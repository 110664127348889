<template>
  <div>
    <StatsLocalisationsTableComponent
      :table-datas="tableDatas"
      :is-loading-table-data="isLoadingTableData"
      title="Nombre de moyens de transport (véhicule, bateaux, aéronefs) disponibles par département / commune / arrondissement"
      :table-columns="tableColumns"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BTable,
  BFormSelect,
  BFormInput,
  BSpinner,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { mapActions, mapState } from "vuex";
import PaginationComponent from "@/components/PaginationComponent.vue";
import StatsLocalisationsTableComponent from "@/components/StatsLocalisationsTableComponent.vue";

// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import statisticsStoreModule from "@/store/statistics";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";
import utilsService from "@/services/utils/utils.service";

const customersPie = {
  series: [690, 258, 149],
  chartOptions: {
    chart: {
      toolbar: {
        show: false,
      },
    },
    labels: ["Billeterie", "Voyage", "Croisière", "Autres"],
    dataLabels: {
      enabled: false,
    },
    legend: { show: false },
    stroke: {
      width: 4,
    },
    colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
  },
};
const departementsPie = {
  series: [690, 258, 149],
  chartOptions: {
    chart: {
      toolbar: {
        show: false,
      },
    },
    labels: ["Billeterie", "Voyage", "Croisière", "Autres"],
    dataLabels: {
      enabled: false,
    },
    legend: { show: false },
    stroke: {
      width: 4,
    },
    colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
  },
};
const communesPie = {
  series: [690, 258, 149],
  chartOptions: {
    chart: {
      toolbar: {
        show: false,
      },
    },
    labels: ["Billeterie", "Voyage", "Croisière", "Autres"],
    dataLabels: {
      enabled: false,
    },
    legend: { show: false },
    stroke: {
      width: 4,
    },
    colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
  },
};

const { departements, communes, arrondissements, quartiers } =
  utilsService.countriesAndPlaces({ stringOnly: false });

export default {
  components: {
    PaginationComponent,
    BSpinner,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
    BTable,
    BFormSelect,
    BFormInput,
    StatsLocalisationsTableComponent,
  },
  props: {
    tableDatas: {
      type: Object,
      default: () => ({
        departements: [],
        communes: [],
        arrondissements: [],
        villages: [],
      }),
    },

    isLoadingTableData: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "statistics", module: statisticsStoreModule },
    ];

    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      selectedTypeLocalisation: "departements",
      selectedTypeEtablissement: "",
      departements,
      communes,
      arrondissements,
      quartiers,
      localSearchQuery: "",
      // tableDatas: {
      //   departements: [],
      //   communes: [],
      //   arrondissements: [],
      //   villages: [],
      // },
      filteredTableDatas: {
        departements: [],
        communes: [],
        arrondissements: [],
        villages: [],
      },
      typesLocalisation: [
        { name: "Départements", key: "departements" },
        { name: "Communes", key: "communes" },
        { name: "Arrondissements", key: "arrondissements" },
        // { name: 'Quartiers', key: 'quartiers' },
      ],
      chartData: {},
      customersPie,
      departementsPie,
      communesPie,
      tableColumns: [
        {
          key: "name",
          label: "Nom",
          sortable: true,
        },
        // { key: 'nbrEts', label: "Nombre d'établissements", sortable: true },
        {
          key: "nombreVehicule",
          label: "Nombre de véhicules",
          sortable: true,
        },
        {
          key: "nombreBateaux",
          label: "Nombre de bateaux ",
          sortable: true,
        },
        {
          key: "nombreAeronefs",
          label: "Nombre d’aéronefs ",
          sortable: true,
        },
        {
          key: "nombreTrains",
          label: "Nombre de trains et assimilés",
          sortable: true,
        },
      ],
      typesEtablissementsOptions: [],
      paginationData: {
        perPage: 10,
        total: 0,
      },
    };
  },
  computed: {
    filterInputPlaceholder() {
      switch (this.selectedTypeLocalisation) {
        case "departements":
          return "Rechercher un département";
          break;

        case "communes":
          return "Rechercher une commune";
          break;
        case "arrondissements":
          return "Rechercher un arrondissement";
          break;
        case "quartiers":
          return "Rechercher un quartier";
          break;

        default:
          return "Rechercher par départements, communes, arrondissements, quartiers";
          break;
      }
    },
    getTableData() {
      const items = [...this.filteredTableDatas[this.selectedTypeLocalisation]];
      this.paginationData.total = items.length || 0;
      const paginateArray = (array, perPage, page) =>
        array.slice((page - 1) * perPage, page * perPage);
      const { perPage, currentPage } = this.paginationData;
      // console.log(items, this.paginationData)
      return paginateArray(items, perPage, currentPage || 1);
      // return this.filteredTableDatas[this.selectedTypeLocalisation];
    },
    getTableColumsTitle() {
      return [
        {
          key: "departement",
          label: "Deoartement",
          sortable: true,
        },
        {
          key: "",
          label: "Nombre d'établissement",
          sortable: true,
        },
      ];
    },
  },
  watch: {
    localSearchQuery: {
      handler(val, old) {},
    },
    selectedTypeLocalisation: {
      handler(val, old) {
        this.filteredTableDatas[this.selectedTypeLocalisation] =
          this.tableDatas[this.selectedTypeLocalisation];
      },
    },
    tableDatas: {
      deep: true,
      immediate: true,
      handler(val, old) {
        this.filteredTableDatas[this.selectedTypeLocalisation] =
          val[this.selectedTypeLocalisation];
      },
    },
  },
  created() {
    this.chartData = {
      lastDays: ["28 Derniers jours", "Mois dernier", "Annee Dernière"],
      listData: [
        {
          icon: "CircleIcon",
          iconColor: "text-primary",
          text: "Billeterie",
          result: 890,
        },
        {
          icon: "CircleIcon",
          iconColor: "text-warning",
          text: "Voyage",
          result: 258,
        },
        {
          icon: "CircleIcon",
          iconColor: "text-danger",
          text: "Croisière",
          result: 149,
        },
        {
          icon: "CircleIcon",
          iconColor: "text-danger",
          text: "Autres",
          result: 149,
        },
      ],
    };
  },

  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  methods: {
    ...mapActions("params", {
      action_loadDepartments: "loadDepartments",
      action_loadCommunes: "loadCommunes",
      action_loadArrondissements: "loadArrondissements",
      action_loadNeighborhoods: "loadNeighborhoods",
    }),
    ...mapActions("statistics", {
      action_getStatisticsAgencesVoyageToursOperators:
        "getStatisticsAgencesVoyageToursOperators",
      action_getStatisticsTransport: "getStatisticsTransport",
    }),
    ...mapActions("etablissements", {
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    onPaginationChange(val) {
      // console.log('paginationChange', val)
      this.paginationData = { ...this.paginationData, ...val };
    },
    localFilter() {
      const query = this.localSearchQuery.toLowerCase();
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.tableDatas[
        this.selectedTypeLocalisation
      ].filter((e) => {
        if (!query) return true;
        return e.name.toLowerCase().includes(query);
      });
      this.filteredTableDatas[this.selectedTypeLocalisation] =
        this.filteredTableDatas[this.selectedTypeLocalisation].filter(
          (e) => true
        );
    },
  },
};
</script>
