<!-- eslint-disable import/no-cycle -->
<template>
  <section>
    <b-card>
      <h1>Statistiques sur les agences de transport de voyageur</h1>
      <div class="mt-2">
        <h3>Filtres de recherche</h3>
        <StatisticsFilters
          v-model="filtersForm"
          :active-filters="activeFilters"
          :is-loading="isLoadingTableData"
          @submit="getStatistics"
        />
      </div>
    </b-card>

    <b-row class="match-height">
      <!-- <b-col lg="6">
        <StatsEtablissementParService />
      </b-col> -->
      <b-col lg="12">
        <StatsEtablissementParType
          :stats-data="statsData.etsCountPerType"
          :is-loading="isLoadingTableData"
        />
      </b-col>
      <b-col lg="12">
        <StatsEtablissementParLocalisation
          :table-datas="statsData.nombreEtsTransportPerLocation.tableDatas"
          :is-loading-table-data="isLoadingTableData"
        />
      </b-col>
      <b-col lg="12">
        <NombreMoyenDeTransportDisponibleParLocalisation
          :is-loading-table-data="isLoadingTableData"
          :table-datas="statsData.ficheMensuellePerLocation.tableDatas"
        />
      </b-col>
      <b-col lg="12">
        <NombreDePlaceDisponible
          :is-loading-table-data="isLoadingTableData"
          :table-datas="statsData.ficheMensuellesPerMoyensDeTransport.tableDatas"
        />
      </b-col>
      <b-col lg="12">
        <NombreDeVoyageurTransportes
          :is-loading-table-data="isLoadingTableData"
          :table-datas="statsData.ficheMensuellesPerMoyensDeTransport.tableDatas"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BCardText,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import { mapActions, mapState } from 'vuex'
import utilsService from '@/services/utils/utils.service'
import StatisticsFilters from '@/components/StatisticsFilters.vue'
import StatsLocalisationsTableComponent from '@/components/StatsLocalisationsTableComponent.vue'

import etablissementsStroreModule from '@/store/etablissements'
import paramsStoreModule from '@/store/params'
import statisticsStoreModule from '@/store/statistics'
// eslint-disable-next-line import/no-cycle
import formulaireStoreModule from '@/store/formulaire'
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import { listMoyensDeTransport as moyensDeTransport } from '@/services/utils/constants'
import { buildFiltersForRequest } from '@/helpers/http-utils'
import StatsEtablissementParType from './StatsEtablissementParType.vue'
import StatsEtablissementParLocalisation from './StatsEtablissementParLocalisation.vue'
import NombreMoyenDeTransportDisponibleParLocalisation from './NombreMoyenDeTransportDisponibleParLocalisation.vue'
import NombreDePlaceDisponible from './NombreDePlaceDisponible.vue'
import NombreDeVoyageurTransportes from './NombreDeVoyageurTransportes.vue'

// store modules and vuex utilities
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle

// eslint-disable-next-line import/no-cycle

const { turnToSnakeCase, turnToCamelCase } = utilsService

const listMoyensDeTransport = moyensDeTransport.map(e => ({
  id: turnToSnakeCase(e),
  name: e,
  [turnToCamelCase(`nombre ${e}`)]: 0,
  nombrePlacesDisponibles: 0,
  nombreVoyageursTransporter: 0,
}))

const activeFilters = ['periode']
const statsData = {
  nombreEtsTransportPerLocation: {
    tableDatas: {
      departements: [],
      communes: [],
      arrondissements: [],
      villages: [],
    },
  },
  ficheMensuellePerLocation: {
    tableDatas: {
      departements: [],
      communes: [],
      arrondissements: [],
      villages: [],
    },
  },
  ficheMensuellesPerMoyensDeTransport: {
    tableDatas: listMoyensDeTransport,
  },
  etsCountPerType: {
    par_eau_: 0,
    par_route: 0,
    par_avion: 0,
    series: [0, 0, 0],
  },
}
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BCardText,
    StatisticsFilters,
    BButton,
    BSpinner,
    StatsEtablissementParType,
    StatsEtablissementParLocalisation,
    NombreMoyenDeTransportDisponibleParLocalisation,
    NombreDePlaceDisponible,
    NombreDeVoyageurTransportes,

    StatsLocalisationsTableComponent,
  },

  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'params', module: paramsStoreModule },
      { path: 'statistics', module: statisticsStoreModule },
      { path: 'formulaire', module: formulaireStoreModule },
    ]

    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  data() {
    return {
      isLoadingTableData: false,

      isLoading: false,
      activeFilters,
      filtersForm: {},

      statsData,
    }
  },
  computed: {
    etablissementId() {
      return utilsService.etablissementId
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },
  async mounted() {
    await this.getStatistics()
  },
  methods: {
    ...mapActions('statistics', {
      action_getStatsEtablissementsDeTransport:
        'getStatsEtablissementsDeTransport',
    }),
    ...mapActions('formulaire', {
      fetchFormulaires: 'fetchFormulaires',
      exportFormulaires: 'exportFormulaires',
    }),
    ...mapActions('etablissements', {
      action_getListTypesEtablissement: 'getListTypesEtablissement',
    }),
    onPaginationChange(val) {
      // console.log('paginationChange', val)
      this.paginationData = { ...this.paginationData, ...val }
    },
    localFilter() {
      const query = this.localSearchQuery.toLowerCase()
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.tableDatas[
        this.selectedTypeLocalisation
      ].filter(e => {
        if (!query) return true
        return e.name.toLowerCase().includes(query)
      })
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.filteredTableDatas[this.selectedTypeLocalisation].filter(e => true)
    },
    async getStatistics() {
      this.isLoadingTableData = true
      const periodeFilter = () => {
        const { from, to } = this.filtersForm.periode || {}
        if (!from && !to) return { createdAt: '' }
        const result = !to ? `${from},${from}` : `${from},${to}`
        return { createdAt: result }
      }

      const filters = {
        ...periodeFilter(),
      }

      delete filters.periode
      const processedFilters = buildFiltersForRequest(filters)
      const reqOptions = {
        params: {
          filter: processedFilters,
        },
      }
      await this.action_getStatsEtablissementsDeTransport(reqOptions)
        .then(async res => {
          // console.log('action_getStatsEtablissementsDeTransport', res)

          // await this.getListTypesEtablissement()
          // const { data } = res
          // const { resources } = data
          // const { options } = resources
          const {
            ficheMensuellePerLocation,
            etsCountPerLocation,
            etsCountPerType,
            ficheMensuellesPerMoyensDeTransport,
          } = res || {}
          this.statsData.etsCountPerType = etsCountPerType
          // console.log(
          //   'statsEtabRestauPerLocation :>> ',
          //   statsEtabRestauPerLocation,
          // )

          const { tableDatas: nombreEtsTransportPerLocation } = this.statsData.nombreEtsTransportPerLocation
          etsCountPerLocation.forEach(localisationItem => {
            const { items, queryId: localisation } = localisationItem
            nombreEtsTransportPerLocation[`${localisation}s`] = items
          })

          const { tableDatas: statsFicheMensuellePerLocation } = this.statsData.ficheMensuellePerLocation
          ficheMensuellePerLocation.forEach(localisationItem => {
            const { items, queryId: localisation } = localisationItem
            statsFicheMensuellePerLocation[`${localisation}s`] = items
          })

          this.statsData.ficheMensuellesPerMoyensDeTransport.tableDatas = [...ficheMensuellesPerMoyensDeTransport]

          // console.log('this.statsData.ficheMensuellesPerMoyensDeTransport.tableDatas  :>> ', this.statsData.ficheMensuellesPerMoyensDeTransport.tableDatas)
          // this.statsData.nombreDePlaceDisponible.tableDatas = nombreDePlaceDisponibleTableData

          this.isLoadingTableData = false

          // console.log('stats', res)
        })
        .catch(error => {
          this.isLoadingTableData = false

          // console.log(error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
